<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Daily Leads
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <div class="row justify-content-end">
                                <div class="col-auto d-flex align-items-center gap-2 flex-nowrap">
                                    <a v-on:click="toggleModal('add_daily_leads')" class="btn btn-primary text-white" style="width: 180px;">
                                        <i class="fas fa-plus fs-16"></i> Tambah Data
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="dailyLeadsQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas daily leads maksimal anda adalah <b>Unlimited</b> daily leads</p>
                </div>
            </div>
            <div v-else-if="dailyLeadsQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas daily leads maksimal anda adalah <b>{{ dailyLeadsQuota }}</b> daily leads</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas daily leads maksimal anda adalah <b>{{ dailyLeadsQuota }}</b> daily leads</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <DailyLeadsTable v-if="renderTable" :date="date" />
                    </div>
                </div>
            </div>

            <modal name="add_daily_leads" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDailyLeads />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import DailyLeadsTable from "@/components/tables/DailyLeads.vue";
    import AddDailyLeads from "@/components/forms/daily-leads/Add.vue";

    export default {
        components: {
            DailyLeadsTable,
            AddDailyLeads
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: [],
                date: "",
                renderTable: false,
                dailyLeadsQuota: 0
            };
        },
        beforeDestroy() {
            this.$root.$off("toggle_modal", this.fnToggleModal);
            this.$root.$off("refresh_data", this.fnRefreshData);
        },
        mounted() {
            this.dailyLeadsQuota = this.$store.state.auth.package.daily_leads

            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fnRefreshData = () => {
                this.renderTable = false
                this.$nextTick(() => {
                    this.renderTable = true
                })
            }
            this.$root.$on("refresh_data", this.fnRefreshData);
            
            //get current date from url
            this.date = this.$route.query.date

            if (!this.date) {
                this.date = new Date().toISOString().split('T')[0]
            }

            this.renderTable = true

        },
        watch: {
            date: function() {
                this.renderTable = false
                this.$nextTick(() => {
                    this.renderTable = true
                })
            }
        },
        methods: {
            async loadGroups() {
                try {
                    let response = await this.$store.dispatch('group_list_dropdown')
                    this.group_dropdown = response.data.data.groups
                } catch (err) {
                    this.$toast.error("Server error")
                }
            },
            async emptyContact() {
                if (confirm("Semua data kontak akan terhapus, Yakin ?")) {
                    try {
                        let response = await this.$store.dispatch('contact_empty')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async repairContact() {
                if (confirm("Data kontak akan diperbaiki, mohon backup data sebelum melakukan repair kontak. Konfirmasi ?")) {
                    try {                        
                        this.$toast.success("Repair kontak sedang diproses");
                        let response = await this.$store.dispatch('contact_repair')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async exportContact() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('contact_export')
                        .then((response) => {
                            let rawData = response.data.data.contacts
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                if (rawData[i].number.startsWith('0')) {
                                    rawData[i].number = '62' + rawData[i].number.substr(1)
                                }

                                final.push({
                                    "Nama": rawData[i].name,
                                    "Whatsapp": rawData[i].number,
                                })

                                let vars

                                if (rawData[i].data) {
                                    vars = JSON.parse(rawData[i].data)
                                } else {
                                    vars = []
                                }
                                for (let j = 0; j < 5; j++) {
                                    final[i]['VARIABEL ' + (j + 1)] = vars[j]?.value?? ""
                                }
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportNow.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            fillVar(val) {
                return val
            },
            toggleModal(param) {
                if (this.group_dropdown.length == 0) this.loadGroups()

                this.$modal.toggle(param);
            },
            async syncDailyLeads() {
                try {
                    this.$toast.success("Sync daily leads sedang diproses");
                    let response = await this.$store.dispatch('daily_leads_sync')
                    this.$toast.success(response.data.message);
                    this.$root.$emit("refresh_table", true)
                    //replace store
                    this.$store.state.auth.package.daily_leads = parseInt(response.data.data.daily_leads)
                    this.dailyLeadsQuota = parseInt(this.$store.state.auth.package.daily_leads)
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>