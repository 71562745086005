<template>
    <div>
        <div style="text-align: center">
            <h3>Tambah Daily Leads</h3>
        </div>
        <br />
        <form v-on:submit.prevent="saveRule">
            <label for="">Nama Daily Leads</label>
            <input type="text" v-model="form.name" class="form-control">
            <br>
            <label for="">Pilih Device</label>
            <multiselect v-model="form.device" tag-placeholder="Pilih device" placeholder="Pilih device" label="name"
                track-by="id" :options="devices" :multiple="true"></multiselect>
            <br>
            <label>Keyword</label>
            <select name="keyword" v-model="form.keyword" class="form-control">
                <option selected="selected" value="or">Jika nilai di bawah memenuhi kondisi "ATAU" (OR)</option>
                <option value="and">Jika nilai di bawah memenuhi kondisi "DAN" (AND)</option>
            </select>
            <br />
            <div class="table-responsive rounded-3 shadow-sm">
                <table class="table mb-0">
                    <thead class="table-light">
                        <tr>
                            <th class="border-0">Kondisi</th>
                            <th class="border-0">Nilai</th>
                            <th class="border-0 text-center" width="80"></th>
                        </tr>
                    </thead>
                    <tbody class="parents-keywords-conditions">
                        <tr class="child-keywords-conditions" v-for="(rule, index) in rules" :key="index">
                            <td class="align-middle">
                                <select role="button"
                                    class="form-control" v-model="rule.condition">
                                    <option value="" disabled selected>-- Pilih Kondisi --</option>
                                    <option  value="equals">Pesan Sama
                                        Dengan</option>
                                    <option value="contains">Pesan Mengandung</option>
                                    <option value="not_equals">Pesan Tidak Sama
                                        Dengan</option>
                                    <option value="not_contains">Pesan Tidak
                                        Mengandung</option>
                                </select>
                            </td>
                            <td class="align-middle">
                                <input type="text" v-model="rule.value" class="form-control"
                                    placeholder="Ex: Minat kak">
                            </td>
                            <td>
                                <button v-on:click="deleteRule(index)"
                                    style="width: 40px; height: 40px; border-radius: 50%; border: none; color: white; 
                                    display: flex; align-items: center; justify-content: center; cursor: pointer; font-size: 16px;"
                                    class=" btn-danger" type="button">
                                    <i class="fa-solid fa-times"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-success btn-sm btn-block mt-1" @click="addRule" type="button">Tambah
                    Kondisi</button>
            </div>
            <br />
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            devices: [],
            form: {
                device: null,
                keyword: "or",
                name: "",
            },
            rules: [{
                condition: "equals",
                value: "halo"
            }],
            null_value: null
        };
    },
    mounted() {
        this.getDevice()
        // this.getRules()
    },
    methods: {
        toggleModal(param) {
            this.$modal.show(param);
        },
        async getDevice() {
            let response = await this.$store.dispatch('device_list_all')
            this.devices = response.data.data.devices.map(device => ({
                id: device.id,
                name: device.name
            }))

        },
        // async getRules() {
        //     let response = await this.$store.dispatch('daily_leads_rule')
        //     let rule = response.data.data.rule
        //     let devices = response.data.data.devices
            
        //     this.form.device = devices.map(device => ({
        //         id: device.device.id,
        //         name: device.device.name
        //     }))

        //     if(rule?.condition != "" && rule?.condition_value != null){
        //         this.form.keyword = rule.condition
        //         this.rules = rule.condition_value
        //     }
        // },
        addRule() {
            this.rules.push({
                condition: "equals",
                value: ""
            });
        },
        deleteRule(index) {
            this.rules.splice(index, 1)
        },
        async saveRule() {
            try {
                let rules = {
                    name: this.form.name,
                    devices: this.form.device.map(device => device.id),
                    keyword: this.form.keyword,
                    conditions: this.rules
                }

                let response = await this.$store.dispatch('daily_leads_create', rules)

                this.$toast.success(response.data.message)
                // this.getRules()

                //close modal
                this.$root.$emit("toggle_modal", "add_daily_leads")
                this.$root.$emit("refresh_data")
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
    },
};
</script>