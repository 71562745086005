<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Data Daily Leads <b>{{ dailyLeads.name }}</b>
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <div class="row justify-content-end">
                                <div class="col-12 d-flex align-items-center gap-2 flex-nowrap">
                                    <input type="date" v-model="date" class="form-control" style="max-width: 250px; margin-right: 10px;">
                                    <a v-on:click="toggleModal('export_data')" class="btn btn-secondary text-white" style="width: 180px; margin-right: 10px;">
                                        <i class="fas fa-download fs-16"></i> Export Data
                                    </a>
                                    <a v-on:click="toggleModal('edit_rules')" class="btn btn-primary text-white" style="width: 180px; margin-right: 10px;">
                                        <i class="fas fa-edit fs-16"></i> Edit Rules
                                    </a>
                                    <!-- <a v-on:click="toggleModal('connect_campaign')" class="btn btn-primary text-white" style="width: 180px;">
                                        Hubungkan dengan Campaign
                                    </a> -->
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <DailyLeadsTable v-if="renderTable" :date="date" :id="id" />
                    </div>
                </div>
            </div>
            <modal name="export_data" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ExportData :date="date" :id="id" />
                </div>
            </modal>
            <modal name="edit_rules" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <EditRules :id="id" />
                </div>
            </modal>
            <modal name="connect_campaign" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ConnectCampaign :id="id" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import DailyLeadsTable from "@/components/tables/DailyLeadsMember.vue";
    import EditRules from "@/components/forms/daily-leads/EditRules.vue";
    import ExportData from "@/components/forms/daily-leads/ExportData.vue";
    import ConnectCampaign from "@/components/forms/daily-leads/ConnectCampaign.vue";

    export default {
        components: {
            DailyLeadsTable,
            EditRules,
            ExportData,
            ConnectCampaign
        },
        data() {
            return {
                id: this.$route.params.id,
                group_dropdown: [],
                exportJson: [],
                date: "",
                renderTable: false,
                dailyLeadsQuota: 0,
                dailyLeads: {
                    name: "",
                }
            };
        },
        beforeDestroy() {
            this.$root.$off("toggle_modal", this.fnToggleModal);
            this.$root.$off("refresh_data", this.fnRefreshData);
        },
        mounted() {
            this.getDailyLeads()

            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fnRefreshData = () => {
                this.renderTable = false
                this.$nextTick(() => {
                    this.renderTable = true
                })
            }
            this.$root.$on("refresh_data", this.fnRefreshData);
            
            //get current date from url
            this.date = this.$route.query.date

            if (!this.date) {
                this.date = new Date().toISOString().split('T')[0]
            }

            this.renderTable = true

        },
        watch: {
            date: function() {
                this.renderTable = false
                this.$nextTick(() => {
                    this.renderTable = true
                })
            }
        },
        methods: {
            async getDailyLeads() {
                let response = await this.$store.dispatch('daily_leads_detail', this.id)
                this.dailyLeads = response?.data?.data?.daily_lead
            },
            async loadGroups() {
                try {
                    let response = await this.$store.dispatch('group_list_dropdown')
                    this.group_dropdown = response.data.data.groups
                } catch (err) {
                    this.$toast.error("Server error")
                }
            },
            async exportContact() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('contact_export')
                        .then((response) => {
                            let rawData = response.data.data.contacts
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                if (rawData[i].number.startsWith('0')) {
                                    rawData[i].number = '62' + rawData[i].number.substr(1)
                                }

                                final.push({
                                    "Nama": rawData[i].name,
                                    "Whatsapp": rawData[i].number,
                                })

                                let vars

                                if (rawData[i].data) {
                                    vars = JSON.parse(rawData[i].data)
                                } else {
                                    vars = []
                                }
                                for (let j = 0; j < 5; j++) {
                                    final[i]['VARIABEL ' + (j + 1)] = vars[j]?.value?? ""
                                }
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportNow.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            fillVar(val) {
                return val
            },
            toggleModal(param) {
                if (this.group_dropdown.length == 0) this.loadGroups()

                this.$modal.toggle(param);
            },
            async syncDailyLeads() {
                try {
                    this.$toast.success("Sync daily leads sedang diproses");
                    let response = await this.$store.dispatch('daily_leads_sync')
                    this.$toast.success(response.data.message);
                    this.$root.$emit("refresh_table", true)
                    //replace store
                    this.$store.state.auth.package.daily_leads = parseInt(response.data.data.daily_leads)
                    this.dailyLeadsQuota = parseInt(this.$store.state.auth.package.daily_leads)
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>