<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        perPageDropdown: [10, 25, 50, 100],
      }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <router-link :to="'/daily-leads/' + props.row.id" class="btn btn-primary btn-sm mr-2">
            <i class="fas fa-edit"></i> Edit
          </router-link>
          <a href="#" class="btn btn-danger btn-sm" v-on:click="deleteDailyLeads(props.row.id)">
            <i class="fas fa-trash"></i> Delete
          </a>
          
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      table: {
        total_records: 100,
        isLoading: true,
        columns: [{
          label: "Nama",
          field: "name",
          filterOptions: {
            enabled: true,
          },
          sortable: false,
          width: "70%",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "30%",
        },
        ],
        rows: [],
        serverParams: {
          columnFilters: {},
          sort: [{
            field: "",
            type: "",
          },],
          page: 1,
          limit: 10,
        },
      },
    };
  },
  mounted() {
    this.fn = () => {
      this.loadItems();
    }
    this.$root.$on("refresh_table", this.fn);
    this.loadItems();
  },
  beforeDestroy() {
    this.$root.$off("refresh_table", this.fn);
  },
  methods: {
    updateParams(newProps) {
      this.table.serverParams = Object.assign({},
        this.table.serverParams,
        newProps
      );
    },
    onPageChange(params) {
      this.updateParams({
        page: params.currentPage
      });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({
        limit: params.currentPerPage
      });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.table.columns[params.columnIndex].field,
        },],
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async loadItems() {
      this.table.isLoading = true
      try {
        let response = await this.getFromServer(this.table.serverParams);
        this.table.total_records = response.data.data.total_records;
        this.table.rows = response.data.data.rules ?? [];
      } catch (err) {
        this.$toast.error("Too many request");
      }
      this.table.isLoading = false
    },
    async getFromServer(param) {
      // console.log(param)
      param.date = this.date
      return await this.$store.dispatch("daily_leads_list", param);
    },
    formatDate(d) {
      let date = new Date(d);
      let months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
      ];

      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();

      return `${day} ${month} ${year}`;
    },
    async deleteDailyLeads(id) {
      try {
        await this.$store.dispatch("delete_daily_leads", id);
        this.$toast.success("Daily leads berhasil dihapus");
        this.loadItems();
      } catch (err) {
        this.$toast.error("Gagal menghapus daily leads");
      }
    }
  },
};
</script>

<style scoped>
.margin5 {
  margin: 5px;
}
</style>