<template>
    <div>
        <div style="text-align: center">
            <h3>Hubungkan dengan Campaign</h3>
        </div>
        <br />
        <form v-on:submit.prevent="saveRule">
            <label for="">Pilih Campaign</label>
            <multiselect v-model="form.campaign" tag-placeholder="Pilih campaign" placeholder="Pilih campaign" label="name"
                track-by="id" :options="campaigns" :multiple="false"></multiselect>
            <br>
            <label>Variabel</label>
            <div class="row" v-for="(v, index) in variable" :key="index">
                <div class="col-md-6">
                    <input type="text" v-model="v.name" class="form-control" readonly>
                </div>
                <div class="col-md-6">
                    <select name="keyword" v-model="v.value" class="form-control">
                        <option selected="selected" value="name">Nama</option>
                        <option value="number">Nomor</option>
                    </select>
                </div>
            </div>
            <br />
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            campaigns: [],
            form: {
                campaign: null,
                keyword: "or",
                name: "",
                number: "",
                variable: [],
                group: null
            },
            rules: [{
                condition: "equals",
                value: "halo"
            }],
            variable: [],
            null_value: null
        };
    },
    mounted() {
        this.getCampaign()
        this.getRules()
    },
    watch: {
        'form.campaign': function(newVal) {
            if(newVal != null) {
                let campaign = this.campaigns.find(campaign => campaign.id === newVal.id)
                let variable = campaign.registrasi.split("#")
                variable.shift()
                this.variable = variable.map(v => ({
                    name: v,
                    value: "name"
                }))
            }else{
                this.variable = []
            }
        }
    },
    methods: {
        toggleModal(param) {
            this.$modal.show(param);
        },
        async getCampaign() {
            let response = await this.$store.dispatch('campaign_list_all_without_pagination')
            this.campaigns = response.data.data.campaigns.map(campaign => ({
                id: campaign.id,
                name: campaign.name,
                registrasi: campaign.registrasi,
            }))

        },
        async getRules() {
            let response = await this.$store.dispatch('daily_leads_rule', { id: this.id })
            let rule = response.data.data.rule

            let campaign = this.campaigns.find(campaign => campaign.id === rule.campaign_id)
            this.form.campaign = {
                id: campaign.id,
                name: campaign.name,
            }
            // let devices = response.data.data.devices

            // this.form.campaign = rule.campaign.id

            // this.form.device = devices.map(device => ({
            //     id: device.device.id,
            //     name: device.device.name
            // }))

            // if (rule?.condition != "" && rule?.condition_value != null) {
            //     this.form.keyword = rule.condition
            //     this.rules = rule.condition_value
            // }
        },
        addRule() {
            this.rules.push({
                condition: "equals",
                value: ""
            });
        },
        deleteRule(index) {
            this.rules.splice(index, 1)
        },
        async saveRule() {
            try {
                let rules = {
                    id: this.id,
                    devices: this.form.device.map(device => device.id),
                    keyword: this.form.keyword,
                    conditions: this.rules
                }

                let response = await this.$store.dispatch('daily_leads_rule_create_or_update', rules)

                this.$toast.success(response.data.message)
                // this.getRules()

                //close modal
                this.$root.$emit("toggle_modal", "edit_rules")
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
    },
};
</script>