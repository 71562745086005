<template>
    <div>
      <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
        :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'chat_at'">
            {{ formatDate(props.row.chat_at) }}
          </span>
          <span v-else-if="props.column.field == 'group_name'">
            <div v-if="props.row.contact && props.row.contact.groups.length > 0"  style="display: flex; flex-direction: column; gap: 4px;">
              <div v-for="group in props.row.contact.groups" :key="group.id">
              <a target="_blank" :href="'/group/edit?id=' + group.group.id"
              style="background-color: #343a40; color: white; padding: 2px 7px; border-radius: 20px; display: block; width: fit-content;font-size: 12px;font-weight: 600;">
                {{ group.group.name }}
                </a>
              </div>
            </div>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      date: {
        type: String,
        default: ""
      },
      id: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [{
            label: "Nama",
            field: "name",
            filterOptions: {
              enabled: true,
            },
            sortable: false,
            width: "10%",
          },
          {
            label: "Nomor Whatsapp",
            field: "number",
            filterOptions: {
              enabled: true,
            },
            sortable: false,
            width: "20%",
          },
          {
            label: "Pesan",
            field: "keyword",
            filterOptions: {
              enabled: true,
            },
            sortable: false,
            width: "30%",
          },
          {
            label: "Group Kontak",
            field: "group_name",
            filterOptions: {
              enabled: false,
            },
            sortable: false,
            width: "20%",
          },
          {
            label: "Tanggal Chat",
            field: "chat_at",
            sortable: false,
            width: "20%",
          },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            },],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          },],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.leads ?? [];
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        // console.log(param)
        param.date = this.date
        param.id = this.id
        return await this.$store.dispatch("daily_leads_member_list", param);
      },
      formatDate(d) {
        let date = new Date(d);
        let months = [
          "Januari", "Februari", "Maret", "April", "Mei", "Juni",
          "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];
  
        let day = date.getDate();
        let month = months[date.getMonth()];
        let year = date.getFullYear();
  
        return `${day} ${month} ${year}`;
      }
    },
  };
  </script>
  
  <style scoped>
  .margin5 {
    margin: 5px;
  }
  </style>