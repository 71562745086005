<template>
    <div>
        <div style="text-align: center">
            <h3>Export Data</h3>
        </div>
        <br>
        <div class="tab-wrapper">
            <div class="atbd-tab tab-horizontal">
                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: page.one }" v-on:click="changePage('one')">Export
                            Ke Group Kontak</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: page.two }" v-on:click="changePage('two')">Download
                            Excel</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1" role="tabpanel"
                        aria-labelledby="tab-v-1-tab">
                        <form v-on:submit.prevent="exportToGroup">
                            <label>Masukkan Data Ke Group</label>
                            <multiselect v-model="form.group" tag-placeholder="Pilih group" placeholder="Pilih group" label="name"
                                track-by="id" :options="groupdd" :multiple="false"></multiselect>
                            <!-- <select v-model="form.group" class="form-control">
                                <option :value="null_value" selected disabled>
                                    == PILIH GROUP ==
                                </option>
                                <option v-for="option in groupdd" :key="option.id" :value="option.id">{{ option.name }}
                                    [ID: {{ option.id }}]</option>
                            </select> -->
                            <br />
                            <button v-if="submitLoading" type="submit" class="btn btn-primary btn-block" disabled>
                                <div class="spinner-border spinner" role="status"></div>
                                Loading
                            </button>
                            <button v-else type="submit" class="btn btn-primary btn-block">
                                Submit
                            </button>
                        </form>
                    </div>
                    <div class="tab-pane fade" :class="{ 'show active': page.two }" role="tabpanel"
                        aria-labelledby="tab-v-2-tab">
                        <button v-if="downloadLoading"
                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block"
                            disabled>
                            <div class="spinner-border spinner" role="status"></div>
                            Loading
                        </button>
                        <button v-else type="button" class="btn btn-danger btn-block" v-on:click="downloadExcel">
                            Download Excel (.csv)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: ["date", "id"],
    data() {
        return {
            inputCari: "",
            placeCari: "Search Group ....",
            page: {
                one: true,
                two: false,
                three: false
            },
            importData: [],
            form: {
                name: "",
                number: "",
                variable: [],
                group: null,
            },
            null_value: null,
            csvParseLoading: false,
            csvImportReady: false,
            device: [],
            importContact: {
                loading: false,
                device: "",
                group: null
            },
            importGroup: {
                step: 1,
                loading: false,
                device: "",
                loadedGroup: [],
                selectedGroup: null,
                group: null
            },
            groupdd: [],
            downloadLoading: false,
            submitLoading: false
        };
    },
    mounted() {
        // this.getDevice()
        this.getGroup()
    },
    methods: {
        async getGroup() {
            let res = await this.$store.dispatch("group_list_dropdown");
            this.groupdd = res.data.data.groups

        },
        changePage(page) {
            this.page.one = false
            this.page.two = false
            this.page.three = false

            if (page == "one") this.page.one = true
            if (page == "two") this.page.two = true
            if (page == "three") this.page.three = true
        },
        async downloadExcel() {
            this.downloadLoading = true;
            try {
                let res = await this.$store.dispatch("daily_leads_export", {
                    id: this.id,
                    date: this.date,
                });

                // Pastikan struktur data benar
                let leads = res.data?.data?.data || [];

                // Format tanggal
                let date = new Date(this.date);
                let dateString = date.getDate().toString().padStart(2, "0") + "-" +
                    (date.getMonth() + 1).toString().padStart(2, "0") + "-" +
                    date.getFullYear();

                // Tambahkan header CSV
                let csv = "Name,Number,Date\n" +
                    leads.map(item => `${item.name},${item.number},${dateString}`).join("\n");

                // Buat file Blob untuk download
                let blob = new Blob([csv], { type: "text/csv" });
                let url = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = `daily_leads_${dateString}.csv`;
                a.click();
                URL.revokeObjectURL(url); // Bersihkan URL blob setelah download

                this.$toast.success("Berhasil export data");
                this.$root.$emit("toggle_modal", "export_data");
            } catch (error) {
                console.error("Gagal export data:", error);
                this.$toast.error("Gagal export data");
            } finally {
                this.downloadLoading = false;
            }
        },
        async exportToGroup() {
            try {
                this.submitLoading = true
                let res = await this.$store.dispatch("daily_leads_export_to_group", {
                    id: this.id,
                    date: this.date,
                    group_id: this.form.group.id
                });
                this.submitLoading = false

                this.$toast.success(res.data.message)

                //close modal
                this.$root.$emit("toggle_modal", "export_data")
                this.$root.$emit("refresh_data", 'daily_leads')
            } catch (error) {
                this.submitLoading = false
                this.$toast.error(error.response.data.message)
            }
        }
    },
};
</script>

<style scoped>
#ulCari {
    border: 1px solid #CCC;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px
}

#ulCari li {
    padding: 3px;
    border-radius: 5px
}

#ulCari li:hover {
    background: #337aff;
    color: white
}

.spinner {
    height: 1rem !important;
    width: 1rem !important;
}

.btn-spinner {
    line-height: 1rem !important;
}

.nav-link {
    cursor: pointer !important;
}
</style>